import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from '@sanity/image-url'

import aos from "aos";
import "aos/dist/aos.css";

import NavBar from '../components/nav-bar/nav-bar.component';

import '../App.css';
import './event-overview.style.css';

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}

export default function AllPosts() {
  const previewImageSize =  Math.round(window.innerWidth/2);
  const [allPostsData, setAllPosts] = useState(null);
  const [isFlex, setIsFlex] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "showroom"]{showroomArray[]{'item':*[_id == ^._ref]}}`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    aos.init({});
  }, []);
useEffect(() =>{
 // console.log(allPostsData.length);
  if (allPostsData && allPostsData.length > 2){
    setIsFlex(true);
  }
},[allPostsData]);
  return (
    <div>
      <NavBar />
      <div className={isFlex ? 'eventGridModeFlex' : 'eventGridMode'}>
        {allPostsData &&
          allPostsData[0].showroomArray.map((post, index) => (
            <div key={index} className='contentGridElement' data-aos="fade-in">
              {console.log(post.item[0])}
                <div className='contentGridImageWrapper'>
                    <Link to={`${post.item[0]._type == 'exhibition' ? '/exhibition/' : '/news/'}${post.item[0].slug.current}`} key={post.item[0].slug.current}>
                        {'exhibitionImages' in post.item[0] ?
                          <img src={urlFor(post.item[0].exhibitionImages[0].image).url()} />
                          :
                          <img src={urlFor(post.item[0].previewImage).url()} />
                        }
                        
                    </Link>
                </div>
                <div className='contentGridElementCaption'>
                    <Link to={"/news/" + post.item[0].slug.current} key={post.item[0].slug.current}>
                        {'exhibitionTitle' in post.item[0] ? post.item[0].exhibitionTitle : 'eventTitle' in post.item[0] ? post.item[0].eventTitle : ''}
                    </Link>
                </div>
            </div>
          ))}
      </div>
    </div>
  );
}