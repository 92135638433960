import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";

import aos from "aos";
import "aos/dist/aos.css";

import NavBar from '../components/nav-bar/nav-bar.component';

import '../App.css';
import './event-overview.style.css';

export default function AllPosts() {
  const previewImageSize =  Math.round(window.innerWidth/2);
  const [allPostsData, setAllPosts] = useState(null);
  const [isFlex, setIsFlex] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "event"]{
          eventTitle,
          slug,
          "previewImageUrl": previewImage.asset->url,
          _updatedAt
        } | order(dateTime(_updatedAt) desc)`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    aos.init({});
  }, []);
useEffect(() =>{
 // console.log(allPostsData.length);
  if (allPostsData && allPostsData.length > 2){
    setIsFlex(true);
  }
},[allPostsData]);
  return (
    <div>
      <NavBar />
      <div className={isFlex ? 'eventGridModeFlex' : 'eventGridMode'}>
        {allPostsData &&
          allPostsData.map((post, index) => (
            <div key={index} className='contentGridElement' data-aos="fade-in">
                <div className='contentGridImageWrapper'>
                    <Link to={"/news/" + post.slug.current} key={post.slug.current}>
                        <img src={`${post.previewImageUrl}?w=${previewImageSize}`} alt='Preview' />
                    </Link>
                </div>
                <div className='contentGridElementCaption'>
                    <Link to={"/news/" + post.slug.current} key={post.slug.current}>
                        {post.eventTitle}
                    </Link>
                </div>
            </div>
          ))}
      </div>
    </div>
  );
}