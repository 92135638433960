import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import frontpage from "./pages/frontpage.page.jsx";
import about from "./pages/about.page.jsx";
import ArtistOverview from "./pages/artist-overview.page.jsx";
import ArtistIndividual from "./pages/artist-individual.page.jsx";
import ExhibitionOverview from "./pages/exhibition-overview.page.jsx";
import ExhibitionIndividual from "./pages/exhibition-individual.page.jsx";
import EventOverview from "./pages/event-overview.page.jsx";
import EventIndividual from "./pages/event-individual.page.jsx";
import Showroom from "./pages/showroom.page.jsx";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Route component={frontpage} path="/" exact />
        <Route component={ArtistOverview} path="/artists/" />
        <Route component={ArtistIndividual} path="/artist/:slug" />
        <Route component={ExhibitionOverview} path="/exhibitions/" />
        <Route component={ExhibitionIndividual} path="/exhibition/:slug" />
        <Route component={Showroom} path="/showroom/" />
        <Route component={EventOverview} path="/newspage/" />
        <Route component={EventIndividual} path="/news/:slug" />
        <Route component={about} path="/about/" />
      </div>
    </BrowserRouter>
  );
}
export default App;
